<template>
    <standard-page sub_title="Permissions" v-bind:title="short_name">
        <template v-slot:breadcrumb>
            <span><router-link :to="{ name: 'identities-home'}">Identifier</router-link></span>
            <span><router-link :to="{ name: 'identities-catalogs'}">Catalogs</router-link></span>
            <span><router-link :to="{ name: 'identities-catalogs-dids'}" v-if="catalogName">CATALOG: {{catalogName | truncate(20, '.....')}}</router-link></span>
            <span v-if="short_name">DID: {{short_name | truncate(20, '.....')}}</span>
        </template>
        <template v-slot:sub-title>
            <p class="mg-b-10">
                <a href="javascript:void(0);" v-clipboard:copy="did" v-clipboard:success="copied">{{did | did}}</a>
            </p>
        </template>
        <template v-slot:content>
            <div>Grant other organizations permission to <b>create claims</b> or <b>authorize claims</b></div>

            <div class="pd-30 pd-sm-40 bg-gray-200 wd-xl-100p">
                <div class="row row-xs align-items-center mg-b-35">
                    <div class="col-md-12">
                        <label class="ckbox">
                            <input type="checkbox" v-model="whole_catalog">
                            <span>Grant permission to all <b>DIDs</b> currently in <b v-if="catalogName">Catalog: {{catalogName | truncate(20, '.....')}}</b></span>
                        </label>
                    </div><!-- col-3 -->
                </div>
                <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                        <label class="form-label mg-b-0">Catalog: <span class="tx-danger">*</span></label>
                    </div><!-- col -->
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <multiselect :options="catalogs" :selectLabel="''" @input="chooseCatalog" class="form-control rounded" data-vv-as="id" id="catalog_id" label="Catalog" name="catalog_id"
                                     open-direction="bottom" placeholder="Select Catalog" v-model="selectedCatalog" v-validate="'required'">
                            <template slot="singleLabel" slot-scope="props">
                                {{props.option.name}}
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <h6>{{ props.option.name | capitalize }}</h6>
                                    <span>{{ props.option.catalog }}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div><!-- col -->
                </div><!-- row -->

                <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                        <label class="form-label mg-b-0">DID: <span class="tx-danger">*</span></label>
                    </div><!-- col -->
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <multiselect :options="dids" :selectLabel="''" @input="chooseDid" class="form-control rounded" data-vv-as="id" id="_did" label="DID" name="did" open-direction="bottom"
                                     placeholder="Select DID" v-model="selectedDid" v-validate="'required'">
                            <template slot="singleLabel" slot-scope="props">
                                {{props.option.short_name}}
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <h6>{{props.option.short_name}}</h6>
                                    <span>{{ props.option.did | truncate(28, "...." )}}</span>
                                </div>
                            </template>
                        </multiselect>
                    </div><!-- col -->
                </div><!-- row -->

                <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                        <label class="form-label mg-b-0">Permission: <span class="tx-danger">*</span></label>
                    </div><!-- col -->
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <multiselect :options="permissions" :selectLabel="''" class="form-control rounded" data-vv-as="permission" id="permission" label="Permission" name="Permission"
                                     open-direction="bottom" placeholder="Choose Permission" v-model="selected_permission">
                            <template slot="singleLabel" slot-scope="props">
                                {{props.option.view_name}}
                            </template>
                            <template slot="option" slot-scope="props">
                                <div class="option__desc">
                                    <h6>{{ props.option.view_name}}</h6>
                                </div>
                            </template>
                        </multiselect>
                    </div><!-- col -->
                </div><!-- row -->

                <div class="row row-xs align-items-center mg-b-20">
                    <div class="col-md-4">
                        <label class="form-label mg-b-0">Permission Validity: <span class="tx-danger">*</span></label>
                    </div><!-- col -->
                    <div class="col-md-8 mg-t-5 mg-md-t-0">
                        <input class="form-control rounded" data-vv-as="Expiry" id="expiry" name="expiry" type="date" v-model="expiry">
                    </div>
                </div>

                <button @click="authorizeDid" class="btn btn-az-primary pd-x-30 mg-r-5">Register</button>
                <button class="btn btn-dark pd-x-30">Cancel</button>
            </div>
        </template>
    </standard-page>
</template>

<script>
    import Multiselect from 'vue-multiselect';
    import EventBus from "@/event-bus";

    export default {
        name: "DidAuthorization",
        components: {Multiselect},
        props: ['catalogid', 'did'],
        data() {
            return {
                submitted: false,
                catalogName: null,
                catalog_id: null,
                short_name: null,
                catalogs: [],
                selectedCatalog: null,
                dids: [],
                selectedDid: null,
                permissions: [
                    {
                        id: 1,
                        view_name: 'Create Claim',
                        name: 'CreateClaim'
                    }, {
                        id: 2,
                        view_name: 'Authorize Claim',
                        name: 'AuthorizeClaim'
                    }
                ],
                selected_permission: null,
                expiry: new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " 00:00",
                whole_catalog: false
            }
        },
        // watch: {
        //     selected_permissionProp: function (nv, ov) {
        //         if (nv) {
        //             if (nv == 'CreateClaim') {
        //                 this.selected_permission = {
        //                     id: 1,
        //                     view_name: 'Create Claim',
        //                     name: 'CreateClaim'
        //                 };
        //             } else {
        //                 this.selected_permission = {
        //                     id: 2,
        //                     view_name: 'Authorize Claim',
        //                     name: 'AuthorizeClaim'
        //                 };
        //             }
        //         }
        //     }
        // },
        mounted() {
            // AmazeUI Datetimepicker
            $('#datetimepicker').datetimepicker({
                format: 'yyyy-mm-dd hh:ii',
                autoclose: true
            });
            this.init();
        },
        methods: {
            async init() {
                await Promise.all([
                    this.getCatalog(),
                    this.getDid(),
                    this.getCatalogs()
                ]);
            },
            async getCatalog() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}`);
                    this.catalogName = reply.data ? reply.data.name : null;
                    this.catalog_id = reply.data ? reply.data.catalog : null;
                } catch (e) {
                    this.$toastr.e("Error getting catalog name", "Error");
                }
            },
            async getDid() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.catalogid}/dids/${this.did}`);
                    this.short_name = reply.data ? reply.data.short_name : null;
                } catch (e) {
                    this.$toastr.e("Error getting DID short_name", "Error");
                }
            },
            copied() {
                this.$toastr.i("Copied to clipboard", "Copied");
            },
            async getCatalogs() {
                try {
                    let reply = await this.$identity.get('identity/catalogs', {params: {page: 0, per_page: 30}});
                    this.catalogs = reply.data ? reply.data.catalogs : [];
                } catch (e) {
                    this.$toastr.e("Error retrieving Catalogs", "Error");
                }
            },
            chooseCatalog(e) {
                if (e) {
                    this.selectedDid = null;
                    this.dids = [];
                    this.getDids();
                }
            },
            async getDids() {
                try {
                    let reply = await this.$identity.get(`/identity/catalogs/${this.selectedCatalog.catalog}/dids`, {
                        params: {
                            page: 0,
                            per_page: 30
                        }
                    });
                    this.dids = reply.data ? reply.data.dids : [];

                } catch (e) {
                    this.$toastr.e("Error retrieving DIDs", "Error");
                }
            },
            chooseDid() {
                //   console.log(this.selectedDid);
            },
            async authorizeDid() {
                try {
                    let data = {};
                    switch (this.selected_permission.name) {
                        case 'CreateClaim':
                            data = {
                                consumers: [{
                                    expiry: new Date(this.expiry).toISOString().slice(0, -1),
                                    did: this.selectedDid.did
                                }
                                ]
                            };
                            break;
                        case 'AuthorizeClaim':
                            data = {
                                issuers: [{
                                    expiry: new Date(this.expiry).toISOString().slice(0, -1),
                                    did: this.selectedDid.did
                                }
                                ]
                            };
                            break;
                    }
                    EventBus.$emit('openLoader');
                    if (this.whole_catalog && this.catalog_id) {
                        data['whole_catalog'] = this.catalog_id
                    }
                    await this.$identity.post(`/identity/dids/${this.did}/authorizations`, data);
                    this.$toastr.s(`Access granted to ${this.selected_permission.view_name}!`, 'Success');
                    this.$router.go(-1);
                } catch (e) {
                    if(!this.whole_catalog) {
                        this.$toastr.e("Error changing authorization", "Error");
                    }
                } finally {
                    EventBus.$emit('closeLoader');
                    if(this.whole_catalog) {
                        this.$toastr.a(`Transactions in queue. `, 'Alert');
                    }
                    this.expiry = new Date().getFullYear() + "-" + (new Date().getMonth() + 1) + "-" + new Date().getDate() + " 00:00";

                }
            }
        }
    }
</script>

<style scoped>

</style>